import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardModule } from './component/dashboard/dashboard.module';
import { AuthGuardGuard } from 'src/commonService/guard/auth-guard.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./component/login/login.module').then(m => m.LoginModule) },
  { path: 'dashboard', loadChildren: () => import('./component/dashboard/dashboard.module').then(m => DashboardModule), canActivate: [AuthGuardGuard] },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
