import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  loginApi(body: any) {
    return this.http.post(this.baseUrl + `v1/admin/auth/login`, body)
  }

  changePasswordApi(body: any) {
    return this.http.post(this.baseUrl + `v1/admin/auth/changePassword`, body)
  }

  dashboard() {
    return this.http.get(this.baseUrl + `v1/admin/getDashboardDetails`)
  }


  usersList(offset: number, limit: number, searchText?: string) {
    const queryParams = new URLSearchParams();
    queryParams.set('offset', offset.toString());
    queryParams.set('limit', limit.toString());

    if (searchText) {
      queryParams.set('search', searchText);
    }
    const url = this.baseUrl + `v1/admin/users/getAllUsers?` + queryParams.toString();
    return this.http.get(url);
  }

  userDetail(id: any) {
    return this.http.get(this.baseUrl + `v1/admin/users/getUser/${id}`)
  }

  userStatus(id: any, body: any) {
    return this.http.put(`${this.baseUrl}v1/admin/users/changeStatus/${id}`, body);
  }

  contactAdmin(body: any) {
    return this.http.post(this.baseUrl + `v1/admin/sendQuickReply`, body)
  }


  getReview(offset: number, limit: number, searchText?: string) {
    const queryParams = new URLSearchParams();
    queryParams.set('offset', offset.toString());
    queryParams.set('limit', limit.toString());

    if (searchText) {
      queryParams.set('search', searchText);
    }
    const url = this.baseUrl + `v1/admin/reviews/getAllReviews?` + queryParams.toString();
    return this.http.get(url);
  }


  deleteReview(id: any) {
    return this.http.get(this.baseUrl + `v1/admin/reviews/deleteReview/${id}`)
  }


  getReport(offset: number, limit: number, searchText?: string) {
    const queryParams = new URLSearchParams();
    queryParams.set('offset', offset.toString());
    queryParams.set('limit', limit.toString());

    if (searchText) {
      queryParams.set('search', searchText);
    }
    const url = this.baseUrl + `v1/admin/reports/getAllReports?` + queryParams.toString();
    return this.http.get(url);
  }

  reportDetail(id: any) {
    return this.http.get(this.baseUrl + `v1/admin/reports/getReportDetails/${id}`)
  }

  reportStatus(id: any, body: any) {
    return this.http.put(`${this.baseUrl}v1/admin/reports/changeStatus/${id}`, body);
  }

  queryDetail(offset: number, limit: number, searchText?: string) {
    const queryParams = new URLSearchParams();
    queryParams.set('offset', offset.toString());
    queryParams.set('limit', limit.toString());
    if (searchText) {
      queryParams.set('search', searchText);
    }
    const url = this.baseUrl + `v1/admin/support/getAllSupportQuestions?` + queryParams.toString();
    return this.http.get(url);
  }

}
