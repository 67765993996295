<div class="wrapper">
    <app-sidebar></app-sidebar>
 <div class="page-content-wrapper">
    <div class="container-fluid bg-white borderradius hv100 px-4">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
  </div>
</div>


