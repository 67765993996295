import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { SidebarComponent } from '../commonpages/sidebar/sidebar.component';
import { FooterComponent } from '../commonpages/footer/footer.component';
import { HeaderComponent } from '../commonpages/header/header.component';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { DashboadinternalComponent } from './dashboadinternal/dashboadinternal.component';
import { UsermangemnetComponent } from './usermangemnet/usermangemnet.component';
import { ReviewmanagementComponent } from './reviewmanagement/reviewmanagement.component';
import { ReportsmanagemnetComponent } from './reportsmanagemnet/reportsmanagemnet.component';
import { QuerymanagemnetComponent } from './querymanagemnet/querymanagemnet.component';
import { SettingComponent } from './setting/setting.component';


@NgModule({
  declarations: [SidebarComponent, FooterComponent, HeaderComponent, DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    RouterModule
  ]
})
export class DashboardModule { }
