import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DashboadinternalComponent } from './dashboadinternal/dashboadinternal.component';

const routes: Routes = [{ path:'', component: DashboardComponent,
children:[
  {path:'', component: DashboadinternalComponent},
{ path: 'usermanagement', loadChildren: () => import('./usermangemnet/usermangemnet.module').then(m => m.UsermangemnetModule) },
{ path: 'querymanagement', loadChildren: () => import('./querymanagemnet/querymanagemnet.module').then(m => m.QuerymanagemnetModule) },
{ path: 'reportmanagement', loadChildren: () => import('./reportsmanagemnet/reportsmanagemnet.module').then(m => m.ReportsmanagemnetModule) },
{ path: 'reviewmanagement', loadChildren: () => import('./reviewmanagement/reviewmanagement.module').then(m => m.ReviewmanagementModule) },
{ path: 'setting', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule) },
]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
