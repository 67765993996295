
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { MessageServiceService } from '../Message/message-service.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public message:MessageServiceService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        console.log(error.error.statusCode)
        switch (error.error.statusCode) {
          case 400:
            /********** handle error msg **********/
            console.log("hello")
            console.log(error.error.message)
            this.message.successMessage(error.error.message, 2000)
            break;
           case 401:
            this.message.errorMessage('Please login again',2000)
           break;
          default:
            /********** Check for other serve-side errors **********/
           
             this.message.successMessage(error.error.message, 1000)
            break;
        }
      }

    }));
 
  
}

}

