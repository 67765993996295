<header>
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between pt-3">
            <div>
                &nbsp;
            </div>
            <div>
                <ul class="ms-auto d-flex align-items-center list-unstyled m-0">
                    <li class="nav-item profile dropdown ms-4">
                        <a class="nav-link  p-0 d-flex align-items-center fw-semibold colorBlack"
                            >
                            <span
                                class="userimg d-flex align-items-center justify-content-center text-white text-uppercase fw-semibold me-3 rounded-circle overflow-hidden">
                               <div class="avtar_50">
                                    <img class="fit-img" [src]="loginUserDetail?.profileImage" alt="" />
                                    <img *ngIf="loginUserDetail?.profileImage==null"
                                        src="assets/images/profile_vector.svg" alt="" width="220px">

                             </div>
                            </span>
                            <span class="username">{{loginUserDetail?.name?.firstName}}
                                {{loginUserDetail?.name?.lastName}}</span>

                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>