import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  loginUserDetail: any = []
  baseUrl = environment.baseUrl
  constructor(private router: Router) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.loginUserDetail = JSON.parse(sessionStorage.getItem('loginUserDetail') || '')
    }, 2000);
  }

  logout() {
    localStorage.clear()
    sessionStorage.clear()
    this.router.navigate(['/'])
  }

}
