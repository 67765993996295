import { Component } from '@angular/core';
import { ApiService } from 'src/commonService/apiList/api.service';

@Component({
  selector: 'app-dashboadinternal',
  templateUrl: './dashboadinternal.component.html',
  styleUrls: ['./dashboadinternal.component.scss']
})
export class DashboadinternalComponent {
  dashboardData: any=[]

  constructor(public api: ApiService) {
    this.dashboard()
  }

  dashboard() {
    this.api.dashboard().subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.dashboardData = res.data
      }
    })
  }

}
